
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import ArticleList from '@/components/articles/ArticleList.component.vue';
  import { Article, ArticleImageDto } from '@/interfaces/arcticles/Article.entity';
  import { AuthenticationService } from '@/services/authentication.service';
  import ArticleDetails from '@/components/articles/ArticleDetails.component.vue';
  import * as UrlConsts from '@/scripts/UrlConsts';


/**
 * @desc page d'affichage détaillé d'un article
 */
  @Component({
    components: {
      'article-details' : ArticleDetails
    },
  })
  export default class ArticleDetailsView extends Vue {
    article : Article = new Article();
    articleId = 0;

    mounted() {
      if(!AuthenticationService.isLoggedIn()) {
        this.$router.push('/connexion');
        return;
      }

      setTimeout(() => {
        this.getArticleId();
      }, 200);
    }

    /**
     * @desc récupère l'id de l'article à afficher
     */
    private getArticleId() : void {
      this.articleId = parseInt(this.$route.params.id);
      this.loadArticle(this.articleId);
    }

    /** 
     * @desc charge l'article indiqué
     */
    private loadArticle(articleId : number) : void {
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(  UrlConsts.getArticle + '/' + articleId, headers)
        .then((response) => {
          this.article = new Article(response.data);
          if(this.article.imgs) (this.article.imgs as ArticleImageDto[]).sort((a, b) => a.order - b.order);
        })
        .catch(error => console.log(error))
    }
  }
